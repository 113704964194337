import MessageMap from '../i18n';

const enUS: MessageMap = {
  BREADCRUMB: {
    COMPANY_PROFILE_SETUP: 'Configuração do perfil da empresa',
    STORES: 'Lojas',
    STORE_DETAILS: 'Detalhes da loja',
    MANAGE_SETTINGS: 'Gerenciar configurações',
    CREATE_STORE: 'Criar loja',
  },
  COMPONENTS: {
    TOGGLE_ACTIVE_LABEL: 'Ativado',
    TOGGLE_INACTIVE_LABEL: 'Desativado',
    FILTER_BY_COUNTRY: 'Filtrar por país',
  },
  ERROR_COMPONENTS: {
    NOT_FOUND_HEADER_TEXT: 'Mmm… Parece que esta página sumiu',
    NOT_FOUND_SUBHEADER_TEXT: 'Parece que esta página não foi encontrada ou não existe.',
    NOT_FOUND_HOME_BTN: 'Voltar',
    ERROR_GENERIC: 'Algo deu errado',
    ERROR_UPLOADING_IMAGE: 'Algo deu errado ao enviar a imagem',
  },
  ACTION_BUTTONS: {
    CANCEL: 'Cancelar',
    CONFIRM: 'Confirmar',
    CLOSE: 'Fechar',
    BACK: 'Voltar',
    GO_BACK: 'Voltar',
    FINISH: 'Finalizar',
    TRY_AGAIN_FAILED: 'Tente novamente',
  },
  STATUS_COLUMN: {
    ACTIVE: 'Ativo',
    INACTIVE: 'Inativo',
    EXPERIMENTATION: 'Experimentação',
  },
  HOME_PAGE: {
    TITLE: 'Lojas',
    CREATE_STORE_BUTTON: 'Criar loja',
    STORE_NAME_COLUMN: 'Nome da loja',
    STORE_ID_COLUMN: 'ID da loja',
    STATUS_COLUMN: 'Status',
    EMPTY_MESSAGE: 'Nenhuma loja encontrada.',
    LOADING_MESSAGE: 'Carregando lojas...',
    COPIED_NOTIFY: 'ID da loja copiado.',
  },
  CREATE_STORE_PAGE: {
    STORE_INFORMATION_TITLE: 'Informações da loja',
    STORE_INFORMATION_SUBTITLE: 'Defina as informações da loja para exibição no aplicativo BEES.',
  },
  STORE_DETAILS_PAGE: {
    TITLE: 'Detalhes da loja {storeName}',
    ASSOCIATED_COMPANIES_TITLE: 'Empresas associadas',
    ASSOCIATED_COMPANIES_SUBTITLE:
      'As empresas serão exibidas no resumo do pedido do aplicativo BEES.',
    STORE_ID_LABEL: 'ID da loja',
    DEFAULT_COMPANY_LABEL: 'Empresa padrão',
    DISPLAY_NAME_COLUMN: 'Nome fantasia e CNPJ',
    VENDOR_ID_COLUMN: 'ID do fornecedor',
    SERVICE_MODEL_COLUMN: 'Modelo de serviço',
    TIER_COLUMN: 'Nível',
    ACTION_COLUMN: 'Ação',
    EMPTY_MESSAGE: 'Nenhum fornecedor encontrado.',
    LOADING_MESSAGE: 'Carregando fornecedores...',
    STORE_COPIED_NOTIFY: 'ID da loja copiado.',
    VENDOR_COPIED_NOTIFY: 'ID do fornecedor copiado.',
    MANAGE_SETTINGS: 'Gerenciar configurações',
    DROPDOWN_OPTION_ASSOCIATE: 'Associar a outra loja',
    DROPDOWN_OPTION_DISSOCIATE: 'Desassociar da loja',
    DROPDOWN_OPTION_DETAILS: 'Ir para detalhes da empresa',
    VENDOR_SEARCH_PLACEHOLDER: 'Procure por nome fantasia ou ID do fornecedor',
    VENDOR_SEARCH_DISPLAY_NAME: 'Nome fantasia',
    VENDOR_SEARCH_VENDOR_ID: 'ID do fornecedor',
    VENDOR_SEARCH_INFO: 'A busca por nome fantasia requer pelo menos 3 caracteres.',
    SHOW_RECENT_ACTIVITY: 'Mostrar atividade recente',
    ASSOCIATED_STORE_LABEL: 'Loja associada',
    ASSOCIATE_MODAL_TITLE: 'Associar empresa a diferentes lojas',
    ASSOCIATE_MODAL_SUBTITLE: 'Associe a empresa {vendorName} a diferentes lojas selecionando-as.',
    CONFIRM_MODAL_TITLE: 'Confirmar associação?',
    CONFIRM_MODAL_PROCESSING_TITLE: 'Associação em andamento...',
    CONFIRM_MODAL_PROCESSED_TITLE: 'Processamento concluído',
    CONFIRM_MODAL_DESCRIPTION:
      'Você está prestes a associar a empresa {vendorName} às lojas selecionadas.',
    CONFIRM_MODAL_PROCESSING_DESCRIPTION:
      'Assim que o processo for concluído, você pode fechar este modal ou tentar novamente.',
    CONFIRM_MODAL_ALL_FAILED_DESCRIPTION:
      'A associação terminou. Agora você pode fechar esta janela ou repetir o processo.',
    CONFIRM_MODAL_PROCESSED_DESCRIPTION:
      'O processo de associação foi concluído. Agora você pode fechar esta janela ou tentar novamente.',
    NO_STORES_SELECTED: 'Nenhuma loja selecionada.',
    STORES_TO_ASSOCIATE: 'Lojas para associar',
    STORES_TO_DISSOCIATE: 'Lojas para dissociar',
    STORE_SUCCESS_STATUS: 'Sucesso',
    STORE_FAILED_STATUS: 'Falha',
    SUCCESS_NOTIFY_MESSAGE: 'A empresa {company} foi atualizada com sucesso.',
    SUCCESS_NOTIFY_BUTTON: 'Ir para a listagem de lojas',
    RECENT_ACTIVITY_HEADER: 'Atividade recente',
    RECENT_ACTIVITY_DESCRIPTION:
      'O registro de auditoria armazena dados por 30 dias. Acesse todas as atividades no aplicativo de registros de auditoria BEES.',
    RECENT_ACTIVITY_STORE_DISSOCIATE_TITLE: "Uma empresa foi desassociada da loja ''{storeName}''.",
    RECENT_ACTIVITY_STORE_DISSOCIATE_DESCRIPTION:
      "''{vendorName}'' foi dissociada da loja ''{storeName}''.",
    RECENT_ACTIVITY_STORE_ASSOCIATE_TITLE: "Nova empresa associada à loja ''{storeName}''.",
    RECENT_ACTIVITY_STORE_ASSOCIATE_DESCRIPTION:
      "Empresa vendedora ''{vendorName}'' foi associada à loja ''{storeName}''.",
    RECENT_ACTIVITY_STORE_EDIT_STATUS_TITLE:
      "A loja ''{storeName}'' foi definida como ''{status}''.",
    RECENT_ACTIVITY_STORE_EDIT_STATUS_DESCRIPTION:
      "O status da loja ''{storeName}'' foi definido como ''{status}''.",
    RECENT_ACTIVITY_STORE_EDIT_IMAGE_TITLE: "O logotipo da loja ''{storeName}'' foi atualizado.",
    RECENT_ACTIVITY_STORE_EDIT_IMAGE_DESCRIPTION:
      "O logotipo da loja ''{storeName}'' foi atualizado com o arquivo ''{imageLink}''.",
    RECENT_ACTIVITY_STORE_EDIT_CONFIGURATIONS_IMAGE_TITLE:
      "A miniatura de {config} da loja ''{storeName}'' foi atualizada.",
    RECENT_ACTIVITY_STORE_EDIT_CONFIGURATIONS_IMAGE_DESCRIPTION:
      "A miniatura de {config} da loja ''{storeName}'' foi atualizada com o arquivo ''{imageLink}''.",
    RECENT_ACTIVITY_STORE_EDIT_CONFIGURATIONS_STATUS_TITLE:
      "A miniatura de {config} ''{storeName}'' foi ''{status}''.",
    RECENT_ACTIVITY_STORE_EDIT_CONFIGURATIONS_STATUS_DESCRIPTION:
      "A miniatura de {config} ''{storeName}'' foi ''{status}''.",
    RECENT_ACTIVITY_STORE_EDIT_DEFAULT_SELLER_TITLE:
      "A empresa padrão ''{storeName}'' foi atualizada.",
    RECENT_ACTIVITY_STORE_EDIT_DEFAULT_SELLER_DESCRIPTION:
      "A empresa padrão ''{storeName}'' foi atualizada para ''{defaultSellerName}''.",
    RECENT_ACTIVITY_STORE_EDIT_NAME_TITLE: "O nome da loja ''{storeName}'' foi atualizado.",
    RECENT_ACTIVITY_STORE_EDIT_NAME_DESCRIPTION:
      "O nome da loja ''{storeName}'' foi atualizado para ''{newStoreName}''.",
    RECENT_ACTIVITY_ERROR_TITLE: 'Algo deu errado',
    RECENT_ACTIVITY_ERROR_DESCRIPTION:
      'Não foi possível carregar as atividades recentes. Por favor, tente novamente mais tarde.',
    RECENT_ACTIVITY_EMPTY_TITLE: 'Sem atividades recentes',
    RECENT_ACTIVITY_EMPTY_DESCRIPTION: 'Não há atividades recentes para mostrar.',
    RECENT_ACTIVITY_CLOSE: 'Fechar',
    RECENT_ACTIVITY_REQUESTOR: 'Solicitante',
    RECENT_ACTIVITY_COPY: 'ID do usuário copiado para a área de transferência',
    RECENT_ACTIVITY_LOAD_MORE_ERROR:
      'Não foi possível obter mais registros de auditoria. Por favor, tente novamente.',
    RECENT_ACTIVITY_STORE_STATUS_ACTIVE: 'ativo',
    RECENT_ACTIVITY_STORE_STATUS_INACTIVE: 'inativo',
    RECENT_ACTIVITY_STORE_STATUS_EXPERIMENTATION: 'experimentação',
    RECENT_ACTIVITY_STORE_CONFIGURATIONS_PROMOTIONS: 'promoções',
    RECENT_ACTIVITY_STORE_CONFIGURATIONS_MISCELLANEOUS: 'miscelânia',
    RECENT_ACTIVITY_STORE_CONFIGURATIONS_STATUS_ON: 'ativada',
    RECENT_ACTIVITY_STORE_CONFIGURATIONS_STATUS_OFF: 'desativada',
    DISSOCIATE_MODAL_HEADER: 'Dissociar esta empresa da loja {store}?',
    DISSOCIATE_MODAL_CONTENT: 'A empresa {company} será dissociada permanentemente da loja {store}',
    DISSOCIATE_MODAL_CONFIRM: 'Sim, dissociar',
    DISSOCIATE_MODAL_CANCEL: 'Não, cancelar',
    DISSOCIATE_MODAL_SUCCESS: 'A empresa {company} foi atualizada com sucesso.',
    DISSOCIATE_MODAL_SUCCESS_BUTTON: 'Ir para a lista de lojas',
    DISSOCIATE_MODAL_ERROR: 'A empresa {company} não pôde ser atualizada.',
    NO_STORES_AVAILABLE_PLACEHOLDER: 'Não há loja disponível para associar',
  },
  STORE_SETTINGS_PAGE: {
    TITLE: 'Gerenciar configurações da loja {storeName}',
    THUMBNAILS_SUBTITLE_OLD: 'Configure miniaturas para serem exibidas no aplicativo do cliente.',
    THUMBNAILS_STORE_LOGO_LABEL: 'Logotipo da loja',
    THUMBNAILS_PROMOTIONS_LABEL: 'Promoções',
    THUMBNAILS_MISCELLANEOUS_LABEL: 'Diversos',
    THUMBNAIL_MODAL_CLOSE: 'Fechar',
    STORE_INFORMATION_TITLE: 'Informações da loja',
    STORE_INFORMATION_SUBTITLE:
      'Defina as informações da loja a serem exibidas no aplicativo do cliente.',
    STORE_INFORMATION_STORE_NAME: 'Nome da loja',
    STORE_INFORMATION_DEFAULT_SELLER: 'Empresa vendedora padrão',
    STORE_INFORMATION_DEFAULT_SELLER_TOOLTIP: 'A empresa padrão é a principal da loja.',
    STORE_INFORMATION_DEFAULT_SELLER_LOADING: 'Carregando...',
    STORE_INFORMATION_STORE_STATUS: 'Status da loja',
    THUMBNAILS_TITLE: 'Miniaturas',
    THUMBNAILS_SUBTITLE: 'Defina as miniaturas a serem exibidas no aplicativo do cliente.',
    THUMBNAILS_PROMOTIONS: 'Promoções',
    THUMBNAILS_MISCELLANEOUS: 'Diversos',
    SAVE_CHANGES_BUTTON: 'Salvar alterações',
    ERROR_LOADING_AVAILABLE_VENDORS: 'Erro ao carregar vendedores disponíveis',
    UPDATE_STORE_SUCCESS: 'Configurações da loja atualizadas com sucesso',
    REQUIRED_STORE_NAME: 'O nome da loja é obrigatório',
  },
  ACCOUNT_TIER: {
    LABEL_TIER_SILVER: 'Prata',
    LABEL_TIER_DEFAULT: 'Prata',
    LABEL_TIER_GOLD: 'Ouro',
  },
  STORE: {
    SEARCH_PLACEHOLDER: 'Buscar por nome ou ID da loja',
  },
};

export default enUS;
