import MessageMap from '../i18n';

const enUS: MessageMap = {
  BREADCRUMB: {
    COMPANY_PROFILE_SETUP: 'Configuración del perfil de la empresa',
    STORES: 'Tiendas',
    STORE_DETAILS: 'Detalles de la tienda',
    MANAGE_SETTINGS: 'Administrar configuraciones',
    CREATE_STORE: 'Crear tienda',
  },
  COMPONENTS: {
    TOGGLE_ACTIVE_LABEL: 'Activo',
    TOGGLE_INACTIVE_LABEL: 'Desactivado',
    FILTER_BY_COUNTRY: 'Filtrar por pais',
  },
  ERROR_COMPONENTS: {
    NOT_FOUND_HEADER_TEXT: 'Mmm… Parece que esta página se ha ido',
    NOT_FOUND_SUBHEADER_TEXT: 'Parece que esta página no se encontró o no existe.',
    NOT_FOUND_HOME_BTN: 'Volver',
    ERROR_GENERIC: 'Algo salió mal',
    ERROR_UPLOADING_IMAGE: 'Algo salió mal al subir la imagen',
  },
  ACTION_BUTTONS: {
    CANCEL: 'Cancelar',
    CONFIRM: 'Confirmar',
    CLOSE: 'Cerrar',
    BACK: 'Volver atrás',
    GO_BACK: 'Volver atrás',
    FINISH: 'Finalizar',
    TRY_AGAIN_FAILED: 'Vuelve a intentar',
  },
  STATUS_COLUMN: {
    ACTIVE: 'Activo',
    INACTIVE: 'Inactivo',
    EXPERIMENTATION: 'Experimentación',
  },
  HOME_PAGE: {
    TITLE: 'Tiendas',
    CREATE_STORE_BUTTON: 'Crear tienda',
    STORE_NAME_COLUMN: 'Nombre de la tienda',
    STORE_ID_COLUMN: 'ID de la tienda',
    STATUS_COLUMN: 'Estado',
    EMPTY_MESSAGE: 'No se encontraron tiendas.',
    LOADING_MESSAGE: 'Cargando tiendas...',
    COPIED_NOTIFY: 'ID de la tienda copiado.',
  },
  CREATE_STORE_PAGE: {
    STORE_INFORMATION_TITLE: 'Información de la tienda',
    STORE_INFORMATION_SUBTITLE:
      'Defina la información de la tienda para mostrar en la aplicación BEES.',
  },
  STORE_DETAILS_PAGE: {
    TITLE: 'Detalles de la tienda {storeName}',
    ASSOCIATED_COMPANIES_TITLE: 'Empresas asociadas',
    ASSOCIATED_COMPANIES_SUBTITLE:
      'Las empresas se mostrarán en el resumen del pedido de la aplicación BEES.',
    STORE_ID_LABEL: 'ID de la tienda',
    DEFAULT_COMPANY_LABEL: 'Tienda predeterminada',
    DISPLAY_NAME_COLUMN: 'Nombre comercial y núm. de identificación fiscal',
    VENDOR_ID_COLUMN: 'ID del proveedor',
    SERVICE_MODEL_COLUMN: 'Modelo de servicio',
    TIER_COLUMN: 'Nivel',
    ACTION_COLUMN: 'Acción',
    EMPTY_MESSAGE: 'No se encontraron proveedores.',
    LOADING_MESSAGE: 'Cargando proveedores...',
    STORE_COPIED_NOTIFY: 'ID de la tienda copiado.',
    VENDOR_COPIED_NOTIFY: 'ID del proveedor copiado.',
    MANAGE_SETTINGS: 'Administrar la configuración',
    DROPDOWN_OPTION_ASSOCIATE: 'Asociar con otra tienda',
    DROPDOWN_OPTION_DISSOCIATE: 'Disociar de la tienda',
    DROPDOWN_OPTION_DETAILS: 'Ir a los detalles de la empresa',
    VENDOR_SEARCH_PLACEHOLDER: 'Buscar por nombre comercial o ID del proveedor',
    VENDOR_SEARCH_DISPLAY_NAME: 'Nombre comercial',
    VENDOR_SEARCH_VENDOR_ID: 'ID del proveedor',
    VENDOR_SEARCH_INFO: 'La búsqueda por nombre comercial requiere al menos 3 caracteres.',
    SHOW_RECENT_ACTIVITY: 'Mostrar actividad reciente',
    ASSOCIATED_STORE_LABEL: 'Tienda vinculada',
    ASSOCIATE_MODAL_TITLE: 'Asocia la compañía con diferentes tiendas',
    ASSOCIATE_MODAL_SUBTITLE:
      'Asocia la compañía {vendorName} con diferentes tiendas seleccionándolas.',
    CONFIRM_MODAL_TITLE: '¿Confirmar vinculación?',
    CONFIRM_MODAL_PROCESSING_TITLE: 'Asociación en progreso...',
    CONFIRM_MODAL_PROCESSED_TITLE: 'Procesamiento finalizado',
    CONFIRM_MODAL_DESCRIPTION:
      'Estás a punto de vincular la compañía {vendorName} con las tiendas seleccionadas.',
    CONFIRM_MODAL_PROCESSING_DESCRIPTION:
      'Una vez que el proceso haya terminado, puedes cerrar este modal o intentarlo de nuevo.',
    CONFIRM_MODAL_ALL_FAILED_DESCRIPTION:
      'La asociación ha terminado. Ahora puedes cerrar esta ventana o repetir el proceso.',
    CONFIRM_MODAL_PROCESSED_DESCRIPTION:
      'El proceso de asociación ha finalizado. Ahora puedes cerrar esta ventana o intentarlo nuevamente.',
    NO_STORES_SELECTED: 'No se han seleccionado tiendas.',
    STORES_TO_ASSOCIATE: 'Tiendas para asociar',
    STORES_TO_DISSOCIATE: 'Tiendas para disociar',
    STORE_SUCCESS_STATUS: 'Éxito',
    STORE_FAILED_STATUS: 'Fallido',
    SUCCESS_NOTIFY_MESSAGE: 'La empresa {company} se actualizó correctamente.',
    SUCCESS_NOTIFY_BUTTON: 'Ir a la lista de tiendas',
    RECENT_ACTIVITY_HEADER: 'Actividad reciente',
    RECENT_ACTIVITY_DESCRIPTION:
      'El registro de auditoría almacena datos durante 30 días. Accede a todas las actividades en la aplicación de registros de auditoría de BEES.',
    RECENT_ACTIVITY_STORE_DISSOCIATE_TITLE:
      "Una empresa fue desvinculada de la tienda ''{storeName}''.",
    RECENT_ACTIVITY_STORE_DISSOCIATE_DESCRIPTION:
      "''{vendorName}'' fue desvinculada de la tienda ''{storeName}''.",
    RECENT_ACTIVITY_STORE_ASSOCIATE_TITLE:
      "La nueva empresa se vinculó a la tienda ''{storeName}''.",
    RECENT_ACTIVITY_STORE_ASSOCIATE_DESCRIPTION:
      "La empresa vendedora ''{vendorName}'' fue vinculada a la tienda ''{storeName}''.",
    RECENT_ACTIVITY_STORE_EDIT_STATUS_TITLE:
      "La tienda ''{storeName}'' se estableció como ''{status}''.",
    RECENT_ACTIVITY_STORE_EDIT_STATUS_DESCRIPTION:
      "El estado de la tienda ''{storeName}'' se estableció como ''{status}''.",
    RECENT_ACTIVITY_STORE_EDIT_IMAGE_TITLE:
      "Se actualizó el logotipo de la tienda ''{storeName}''.",
    RECENT_ACTIVITY_STORE_EDIT_IMAGE_DESCRIPTION:
      "El logotipo de la tienda ''{storeName}'' se actualizó con el archivo ''{imageLink}''.",
    RECENT_ACTIVITY_STORE_EDIT_CONFIGURATIONS_IMAGE_TITLE:
      "Se actualizó la miniatura de {config} de ''{storeName}''.",
    RECENT_ACTIVITY_STORE_EDIT_CONFIGURATIONS_IMAGE_DESCRIPTION:
      "La miniatura de {config} de ''{storeName}'' se actualizó con el archivo ''{imageLink}''.",
    RECENT_ACTIVITY_STORE_EDIT_CONFIGURATIONS_STATUS_TITLE:
      "La miniatura de {config} de ''{storeName}'' se ''{status}''.",
    RECENT_ACTIVITY_STORE_EDIT_CONFIGURATIONS_STATUS_DESCRIPTION:
      "La miniatura de {config} de ''{storeName}'' se ''{status}''.",
    RECENT_ACTIVITY_STORE_EDIT_DEFAULT_SELLER_TITLE:
      "Se actualizó la compañía predeterminada ''{storeName}''.",
    RECENT_ACTIVITY_STORE_EDIT_DEFAULT_SELLER_DESCRIPTION:
      "La compañía predeterminada ''{storeName}'' se actualizó a ''{defaultSellerName}''.",
    RECENT_ACTIVITY_STORE_EDIT_NAME_TITLE: "Se actualizó el nombre de la tienda ''{storeName}''.",
    RECENT_ACTIVITY_STORE_EDIT_NAME_DESCRIPTION:
      "El nombre de la tienda ''{storeName}'' se actualizó a ''{newStoreName}''.",
    RECENT_ACTIVITY_ERROR_TITLE: 'Algo salió mal',
    RECENT_ACTIVITY_ERROR_DESCRIPTION:
      'No se pudieron cargar las actividades recientes. Por favor, inténtalo de nuevo más tarde.',
    RECENT_ACTIVITY_EMPTY_TITLE: 'No hay actividades recientes',
    RECENT_ACTIVITY_EMPTY_DESCRIPTION: 'No hay actividades recientes para mostrar.',
    RECENT_ACTIVITY_CLOSE: 'Cerrar',
    RECENT_ACTIVITY_REQUESTOR: 'Solicitante',
    RECENT_ACTIVITY_COPY: 'ID de usuario copiado al portapapeles',
    RECENT_ACTIVITY_LOAD_MORE_ERROR:
      'No se pueden obtener más registros de auditoría. Por favor, inténtalo de nuevo.',
    RECENT_ACTIVITY_STORE_STATUS_ACTIVE: 'activo',
    RECENT_ACTIVITY_STORE_STATUS_INACTIVE: 'inactivo',
    RECENT_ACTIVITY_STORE_STATUS_EXPERIMENTATION: 'experimentación',
    RECENT_ACTIVITY_STORE_CONFIGURATIONS_PROMOTIONS: 'promociones',
    RECENT_ACTIVITY_STORE_CONFIGURATIONS_MISCELLANEOUS: 'miscelánea',
    RECENT_ACTIVITY_STORE_CONFIGURATIONS_STATUS_ON: 'activô',
    RECENT_ACTIVITY_STORE_CONFIGURATIONS_STATUS_OFF: 'desactivô',
    DISSOCIATE_MODAL_HEADER: '¿Desvincular esta compañía de la tienda de {store}?',
    DISSOCIATE_MODAL_CONTENT:
      'La compañía {company} se desvinculará permanentemente de la tienda {store}.',
    DISSOCIATE_MODAL_CONFIRM: 'Sí, desvincular',
    DISSOCIATE_MODAL_CANCEL: 'No, cancelar',
    DISSOCIATE_MODAL_SUCCESS: 'La empresa {company} se actualizó correctamente.',
    DISSOCIATE_MODAL_SUCCESS_BUTTON: 'Ir a la lista de tiendas',
    DISSOCIATE_MODAL_ERROR: 'No se pudo actualizar la empresa {company}.',
    NO_STORES_AVAILABLE_PLACEHOLDER: 'No hay tienda disponible para asociar',
  },
  STORE_SETTINGS_PAGE: {
    TITLE: 'Administrar la configuración de la tienda {storeName}',
    THUMBNAILS_SUBTITLE_OLD:
      'Configura las miniaturas que se mostrarán en la aplicación del cliente.',
    THUMBNAILS_STORE_LOGO_LABEL: 'Logotipo de la tienda',
    THUMBNAILS_PROMOTIONS_LABEL: 'Promociones',
    THUMBNAILS_MISCELLANEOUS_LABEL: 'Varios',
    THUMBNAIL_MODAL_CLOSE: 'Cerrar',
    STORE_INFORMATION_TITLE: 'Información de la tienda',
    STORE_INFORMATION_SUBTITLE:
      'Define la información de la tienda que se mostrará en la aplicación del cliente.',
    STORE_INFORMATION_STORE_NAME: 'Nombre de la tienda',
    STORE_INFORMATION_DEFAULT_SELLER: 'Compañía vendedora predeterminada',
    STORE_INFORMATION_DEFAULT_SELLER_TOOLTIP:
      'La compañía predeterminada es la compañía principal de la tienda. ',
    STORE_INFORMATION_DEFAULT_SELLER_LOADING: 'Cargando...',
    STORE_INFORMATION_STORE_STATUS: 'Estado de la tienda',
    THUMBNAILS_TITLE: 'Miniaturas',
    THUMBNAILS_SUBTITLE: 'Define las miniaturas que se mostrarán en la aplicación del cliente.',
    THUMBNAILS_PROMOTIONS: 'Promociones',
    THUMBNAILS_MISCELLANEOUS: 'Varios',
    SAVE_CHANGES_BUTTON: 'Guardar cambios',
    ERROR_LOADING_AVAILABLE_VENDORS: 'Error al cargar los vendedores disponibles',
    UPDATE_STORE_SUCCESS: 'Configuración de la tienda actualizada correctamente',
    REQUIRED_STORE_NAME: 'El nombre de la tienda es obligatorio',
  },
  ACCOUNT_TIER: {
    LABEL_TIER_SILVER: 'Plata',
    LABEL_TIER_DEFAULT: 'Plata',
    LABEL_TIER_GOLD: 'Oro',
  },
  STORE: {
    SEARCH_PLACEHOLDER: 'Búsqueda por nombre de tienda o ID',
  },
};

export default enUS;
