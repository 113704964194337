import { useAuthenticationService } from 'admin-portal-shared-services';
import { Error403 } from 'components/Error/403/403';
import { Error404 } from 'components/Error/404/404';
import { useFeatureToggles } from 'hooks/useFeatureToggles';
import { useGetSupportedCountries } from 'hooks/useGetSupportedCountries';
import { usePermissions } from 'hooks/usePermissions';
import { lazy, Suspense, useState } from 'react';
import {
  createBrowserRouter,
  LoaderFunction,
  Outlet,
  replace,
  RouteObject,
  RouterProvider,
} from 'react-router-dom';
import { Loading } from 'theme/globalStyles';

export const COMPANY_BASE_URL = '/company-management';
export const STORES_BASE_URL = `${COMPANY_BASE_URL}/stores`;
export const HOME_PAGE_URL = `${STORES_BASE_URL}/:country?`;
export const CREATE_STORE_PAGE_URL = `${STORES_BASE_URL}/:country/create`;
export const STORE_DETAILS_PAGE_URL = `${STORES_BASE_URL}/:country/:id`;
export const STORE_SETTINGS_PAGE_URL = `${STORE_DETAILS_PAGE_URL}/settings`;

const HomePage = lazy(() => import('./pages/HomePage/HomePage'));
const CreateStorePage = lazy(() => import('./pages/CreateStorePage/CreateStorePage'));
const StoreDetailsPage = lazy(() => import('./pages/StoreDetailsPage/StoreDetailsPage'));
const StoreSettingsPage = lazy(() => import('./pages/StoreSettingsPage/StoreSettingsPage'));

export const Router = (): JSX.Element => {
  const authentication = useAuthenticationService();
  const { user_country: userCountry } = authentication.getUserCountryAndLanguage();
  const { canAccessStoreManagement, canManageStores } = usePermissions();
  const { showCreateStores, isSomeToggleLoading } = useFeatureToggles();
  const supportedCountries = useGetSupportedCountries();

  const [hasError, setHasError] = useState(false);

  const handleLoadWithInvalidCountry: LoaderFunction = async ({ params, request }) => {
    const country = params.country?.toUpperCase() ?? '';
    const { url } = request;
    const endsWithBaseUrl = new RegExp(`${STORES_BASE_URL}/?$`).test(url);
    if (!country && endsWithBaseUrl) {
      return replace(`${STORES_BASE_URL}/${userCountry}`);
    }
    if (!supportedCountries.includes(country)) {
      setHasError(true);
    }
    return null;
  };

  let routes: RouteObject[] = [
    {
      element: (
        <Suspense fallback={<Loading data-testid="router-loading-id" />}>
          <Outlet />
        </Suspense>
      ),
      loader: handleLoadWithInvalidCountry,
      children: [
        {
          index: true,
          path: HOME_PAGE_URL,
          element: <HomePage />,
        },
        {
          path: CREATE_STORE_PAGE_URL,
          element: canManageStores && showCreateStores ? <CreateStorePage /> : <Error403 />,
        },
        {
          path: STORE_DETAILS_PAGE_URL,
          element: <StoreDetailsPage />,
        },
        {
          path: STORE_SETTINGS_PAGE_URL,
          element: canManageStores ? <StoreSettingsPage /> : <Error403 />,
        },
      ],
    },
    {
      path: '*',
      element: <Error404 />,
    },
  ];

  if (!canAccessStoreManagement) {
    return <Error403 />;
  }

  if (isSomeToggleLoading) {
    return <Loading data-testid="router-loading-id" />;
  }

  if (hasError) {
    routes = [
      {
        path: '*',
        element: <Error404 />,
      },
    ];
  }

  return <RouterProvider router={createBrowserRouter(routes)} />;
};
