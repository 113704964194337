import { Grid } from '@hexa-ui/components';
import { createGenerateClassName, StylesProvider } from '@material-ui/core';
import { useInitializeFeatureToggles } from 'hooks/useInitializeFeatureToggles';
import { useLoadAnalytics } from 'hooks/useLoadAnalytics';
import { QueryClientProvider } from 'providers/QueryClientProvider';
import { Provider } from 'react-redux';
import { Router } from 'Router';
import Logger from 'utils/log/Logger';
import { store } from './app/store';
import { IntlProvider } from './i18n';
import { globalStyles } from './theme/globalStyles';

export type EnvProps = {
  name: string;
  optimizelyKey: string;
  segmentKey: string;
};

export default function App({ name, optimizelyKey, segmentKey }: EnvProps) {
  globalStyles();

  useLoadAnalytics(segmentKey);

  const generateClassName = createGenerateClassName({
    productionPrefix: name,
    seed: name,
  });

  const { isReady: showStoreApp } = useInitializeFeatureToggles(optimizelyKey);

  if (!showStoreApp) {
    return null;
  }

  return (
    <QueryClientProvider>
      <Provider store={store}>
        <StylesProvider generateClassName={generateClassName}>
          <IntlProvider>
            <Grid.Container
              style={{
                rowGap: '1rem',
                display: 'block',
              }}
              type="fluid"
              sidebar
            >
              <Router />
              <Logger />
            </Grid.Container>
          </IntlProvider>
        </StylesProvider>
      </Provider>
    </QueryClientProvider>
  );
}
