import MessageMap from '../i18n.d';

const enUS: MessageMap = {
  test: {
    translate: 'Translate',
    missing: 'Only English option available',
  },
  BREADCRUMB: {
    COMPANY_PROFILE_SETUP: 'Company profile setup',
    STORES: 'Stores',
    STORE_DETAILS: 'Store details',
    MANAGE_SETTINGS: 'Manage settings',
    CREATE_STORE: 'Create store',
  },
  COMPONENTS: {
    TOGGLE_ACTIVE_LABEL: 'On',
    TOGGLE_INACTIVE_LABEL: 'Off',
    FILTER_BY_COUNTRY: 'Filter by country',
  },
  ERROR_COMPONENTS: {
    NOT_FOUND_HEADER_TEXT: 'Mmm… Looks like this page is gone',
    NOT_FOUND_SUBHEADER_TEXT: "It looks like this page wasn't found or doesn't exist.",
    NOT_FOUND_HOME_BTN: 'Back',
    ERROR_GENERIC: 'Something went wrong',
    ERROR_UPLOADING_IMAGE: 'Something went wrong while uploading the image',
  },
  ACTION_BUTTONS: {
    CANCEL: 'Cancel',
    CONFIRM: 'Confirm',
    CLOSE: 'Close',
    BACK: 'Back',
    GO_BACK: 'Go back',
    FINISH: 'Finish',
    TRY_AGAIN_FAILED: 'Try again failed',
  },
  STATUS_COLUMN: {
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
    EXPERIMENTATION: 'Experimentation',
  },
  HOME_PAGE: {
    TITLE: 'Stores',
    CREATE_STORE_BUTTON: 'Create store',
    STORE_NAME_COLUMN: 'Store Name',
    STORE_ID_COLUMN: 'Store ID',
    STATUS_COLUMN: 'Status',
    EMPTY_MESSAGE: 'No stores found.',
    LOADING_MESSAGE: 'Loading stores...',
    COPIED_NOTIFY: 'Store ID copied.',
  },
  CREATE_STORE_PAGE: {
    STORE_INFORMATION_TITLE: 'Store information',
    STORE_INFORMATION_SUBTITLE: 'Define the store information to be displayed on the customer app.',
  },
  STORE_DETAILS_PAGE: {
    TITLE: '{storeName} store details',
    ASSOCIATED_COMPANIES_TITLE: 'Associated companies',
    ASSOCIATED_COMPANIES_SUBTITLE: 'Companies will be displayed on the BEES app checkout.',
    STORE_ID_LABEL: 'Store ID',
    DEFAULT_COMPANY_LABEL: 'Default company',
    DISPLAY_NAME_COLUMN: 'Display name and Tax ID',
    VENDOR_ID_COLUMN: 'Vendor ID',
    SERVICE_MODEL_COLUMN: 'Service Model',
    TIER_COLUMN: 'Tier',
    ACTION_COLUMN: 'Action',
    EMPTY_MESSAGE: 'No vendors found.',
    LOADING_MESSAGE: 'Loading vendors...',
    STORE_COPIED_NOTIFY: 'Store ID copied.',
    VENDOR_COPIED_NOTIFY: 'Vendor ID copied.',
    MANAGE_SETTINGS: 'Manage settings',
    DROPDOWN_OPTION_ASSOCIATE: 'Associate with different store',
    DROPDOWN_OPTION_DISSOCIATE: 'Dissociate from store',
    DROPDOWN_OPTION_DETAILS: 'Go to company details',
    VENDOR_SEARCH_PLACEHOLDER: 'Search by display name or vendor ID',
    VENDOR_SEARCH_DISPLAY_NAME: 'Display name',
    VENDOR_SEARCH_VENDOR_ID: 'Vendor ID',
    VENDOR_SEARCH_INFO: 'Display name search requires at least 3 characters.',
    SHOW_RECENT_ACTIVITY: 'Show recent activity',
    ASSOCIATED_STORE_LABEL: 'Associated store',
    ASSOCIATE_MODAL_TITLE: 'Associate company with different stores',
    ASSOCIATE_MODAL_SUBTITLE:
      'Associate the company {vendorName} with different stores by selecting them.',
    CONFIRM_MODAL_TITLE: 'Confirm association?',
    CONFIRM_MODAL_PROCESSING_TITLE: 'Association in progress...',
    CONFIRM_MODAL_PROCESSED_TITLE: 'Processing finished',
    CONFIRM_MODAL_DESCRIPTION:
      'You are about to associate {vendorName} company with the selected stores.',
    CONFIRM_MODAL_PROCESSING_DESCRIPTION:
      'Once the process is finished, you can close this modal or try again.',
    CONFIRM_MODAL_ALL_FAILED_DESCRIPTION:
      'Association has finished. You can now close this window or repeat the process.',
    CONFIRM_MODAL_PROCESSED_DESCRIPTION:
      'Association process has finished. You can now close this window or try again.',
    NO_STORES_SELECTED: 'No stores selected.',
    STORES_TO_ASSOCIATE: 'Stores to associate to',
    STORES_TO_DISSOCIATE: 'Stores to dissociate from',
    STORE_SUCCESS_STATUS: 'Success',
    STORE_FAILED_STATUS: 'Failed',
    SUCCESS_NOTIFY_MESSAGE: '{company} company was successfully updated.',
    SUCCESS_NOTIFY_BUTTON: 'Go to store list',
    RECENT_ACTIVITY_HEADER: 'Recent activity',
    RECENT_ACTIVITY_DESCRIPTION:
      'The audit log stores data for 30 days. Access all activities in BEES audit logs application.',
    RECENT_ACTIVITY_STORE_DISSOCIATE_TITLE: "A company was dissociated from ''{storeName}'' store.",
    RECENT_ACTIVITY_STORE_DISSOCIATE_DESCRIPTION:
      "''{vendorName}'' was dissociated from ''{storeName}'' store.",
    RECENT_ACTIVITY_STORE_ASSOCIATE_TITLE: "New company was associated to ''{storeName}'' store.",
    RECENT_ACTIVITY_STORE_ASSOCIATE_DESCRIPTION:
      "Seller company ''{vendorName}'' was associated to ''{storeName}'' store.",
    RECENT_ACTIVITY_STORE_EDIT_STATUS_TITLE: "''{storeName}'' store was set as ''{status}''.",
    RECENT_ACTIVITY_STORE_EDIT_STATUS_DESCRIPTION:
      "''{storeName}'' store status was set to ''{status}''.",
    RECENT_ACTIVITY_STORE_EDIT_IMAGE_TITLE: "''{storeName}'' store logo was updated.",
    RECENT_ACTIVITY_STORE_EDIT_IMAGE_DESCRIPTION:
      "''{storeName}'' store logo was updated with file ''{imageLink}''.",
    RECENT_ACTIVITY_STORE_EDIT_CONFIGURATIONS_IMAGE_TITLE:
      "''{storeName}'' {config} thumbnail was updated.",
    RECENT_ACTIVITY_STORE_EDIT_CONFIGURATIONS_IMAGE_DESCRIPTION:
      "''{storeName}'' {config} thumbnail was updated with file ''{imageLink}''.",
    RECENT_ACTIVITY_STORE_EDIT_CONFIGURATIONS_STATUS_TITLE:
      "''{storeName}'' {config} thumbnail was turned ''{status}''.",
    RECENT_ACTIVITY_STORE_EDIT_CONFIGURATIONS_STATUS_DESCRIPTION:
      "''{storeName}'' {config} thumbnail was turned ''{status}''.",
    RECENT_ACTIVITY_STORE_EDIT_DEFAULT_SELLER_TITLE: "''{storeName}'' default company was updated.",
    RECENT_ACTIVITY_STORE_EDIT_DEFAULT_SELLER_DESCRIPTION:
      "''{storeName}'' default company was updated to ''{defaultSellerName}''.",
    RECENT_ACTIVITY_STORE_EDIT_NAME_TITLE: "''{storeName}'' store name was updated.",
    RECENT_ACTIVITY_STORE_EDIT_NAME_DESCRIPTION:
      "''{storeName}'' store name was updated to ''{newStoreName}''.",
    RECENT_ACTIVITY_ERROR_TITLE: 'Something went wrong',
    RECENT_ACTIVITY_ERROR_DESCRIPTION:
      "Recent activities couldn't be loaded. Please try again later.",
    RECENT_ACTIVITY_EMPTY_TITLE: 'No recent activities',
    RECENT_ACTIVITY_EMPTY_DESCRIPTION: 'There is no recent activities to show.',
    RECENT_ACTIVITY_CLOSE: 'Close',
    RECENT_ACTIVITY_REQUESTOR: 'Requestor',
    RECENT_ACTIVITY_COPY: 'User ID copied to clipboard',
    RECENT_ACTIVITY_LOAD_MORE_ERROR: 'Unable to get more audit logs. Please try again.',
    RECENT_ACTIVITY_STORE_STATUS_ACTIVE: 'active',
    RECENT_ACTIVITY_STORE_STATUS_INACTIVE: 'inactive',
    RECENT_ACTIVITY_STORE_STATUS_EXPERIMENTATION: 'experimentation',
    RECENT_ACTIVITY_STORE_CONFIGURATIONS_PROMOTIONS: 'promotions',
    RECENT_ACTIVITY_STORE_CONFIGURATIONS_MISCELLANEOUS: 'miscellaneous',
    RECENT_ACTIVITY_STORE_CONFIGURATIONS_STATUS_ON: 'on',
    RECENT_ACTIVITY_STORE_CONFIGURATIONS_STATUS_OFF: 'off',
    DISSOCIATE_MODAL_HEADER: 'Dissociate this company from the {store} store?',
    DISSOCIATE_MODAL_CONTENT:
      'The company {company} will be permanently dissociated from the store {store}.',
    DISSOCIATE_MODAL_CONFIRM: 'Yes, dissociate',
    DISSOCIATE_MODAL_CANCEL: 'No, cancel',
    DISSOCIATE_MODAL_SUCCESS: '{company} company was successfully updated.',
    DISSOCIATE_MODAL_SUCCESS_BUTTON: 'Go to store list',
    DISSOCIATE_MODAL_ERROR: "{company} company couldn't be updated.",
    NO_STORES_AVAILABLE_PLACEHOLDER: 'There is no store available to associate',
  },
  STORE_SETTINGS_PAGE: {
    TITLE: 'Manage {storeName} store settings',
    THUMBNAILS_SUBTITLE_OLD: 'Set up thumbnails to be displayed on the customer app.',
    THUMBNAILS_STORE_LOGO_LABEL: 'Store logo',
    THUMBNAILS_PROMOTIONS_LABEL: 'Promotions',
    THUMBNAILS_MISCELLANEOUS_LABEL: 'Miscellaneous',
    THUMBNAIL_MODAL_CLOSE: 'Close',
    STORE_INFORMATION_TITLE: 'Store information',
    STORE_INFORMATION_SUBTITLE: 'Define the store information to be displayed on the customer app.',
    STORE_INFORMATION_STORE_NAME: 'Store name',
    STORE_INFORMATION_DEFAULT_SELLER: 'Default seller company',
    STORE_INFORMATION_DEFAULT_SELLER_TOOLTIP: 'Default company is the main company of the store.',
    STORE_INFORMATION_DEFAULT_SELLER_LOADING: 'Loading...',
    STORE_INFORMATION_STORE_STATUS: 'Store status',
    THUMBNAILS_TITLE: 'Thumbnails',
    THUMBNAILS_SUBTITLE: 'Define the thumbnails to be displayed on the customer app.',
    THUMBNAILS_PROMOTIONS: 'Promotions',
    THUMBNAILS_MISCELLANEOUS: 'Miscellaneous',
    SAVE_CHANGES_BUTTON: 'Save changes',
    ERROR_LOADING_AVAILABLE_VENDORS: 'Error loading available sellers',
    UPDATE_STORE_SUCCESS: 'Store settings successfully updated',
    REQUIRED_STORE_NAME: 'Store name is required',
  },
  ACCOUNT_TIER: {
    LABEL_TIER_SILVER: 'Silver',
    LABEL_TIER_DEFAULT: 'Silver',
    LABEL_TIER_GOLD: 'Gold',
  },
  STORE: {
    SEARCH_PLACEHOLDER: 'Search by store name or ID',
  },
};

export default enUS;
